import type { ApiResult, BlindBoxResult, LoginByGoogleResult } from '@/lib/api/models'
import { client } from '@/lib/api'
import { isDeBoxApp } from '@/lib/utils'
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { useFarmsQueryOptions, useFriendsQueryOptions, usePurchaseRecordsQueryOptions, useSignRecordsQueryOptions, useTasksQueryOptions, useUserInfoQueryOptions, useWarehouseQueryOptions } from '.'
import { userToken } from '../user'

export function useLoginByDeBoxMutation() {
  return useMutation({
    mutationKey: ['login-by', 'debox'],
    mutationFn: ({ code, user_id }: { code: string, user_id: string }) => {
      return client<ApiResult<LoginByGoogleResult>>('/api/user/deboxLogin', {
        method: 'POST',
        body: {
          code,
          user_id,
          invite_code: sessionStorage.getItem('invite-code'),
        },
      })
    },
    onSuccess({ data }) {
      if (typeof data.token === 'string') {
        userToken.value = (data.token)
        userAddress.value = window.deboxWallet?.address
      }
    },
  })
}

export function useLoginByGoogleMutation() {
  return useMutation({
    mutationKey: ['login-by', 'google'],
    mutationFn: (code: string) => {
      return client<ApiResult<LoginByGoogleResult>>('/api/user/googleLogin', {
        method: 'POST',
        body: {
          code,
          invite_code: sessionStorage.getItem('invite-code'),
        },
      })
    },
    onSuccess({ data }) {
      if (typeof data.token === 'string') {
        // const expirationTime = dayjs(created * 1000).add(expires_in, 'second')

        // console.info('📔 Expiration Time', expirationTime.format())
        // setToken(id_token, expirationTime.valueOf())
        userToken.value = (data.token)
      }
    },
  })
}

export function useCollectFarmMutation() {
  const qc = useQueryClient()
  return useMutation({
    mutationKey: ['collect', 'farm'],
    /**
     * @param levelId same as farm id/floor id
     */
    mutationFn: (levelId: number) => {
      return client<ApiResult>('/api/user/collect', {
        method: 'POST',
        body: {
          level: levelId,
        },
      })
    },
    onSuccess() {
      qc.invalidateQueries(useFarmsQueryOptions())
    },
  })
}

export function useDailySignMutation() {
  const qc = useQueryClient()
  return useMutation({
    mutationKey: ['daily-sign'],
    mutationFn: () => client<ApiResult>('/api/daily/sign', {
      method: 'POST',
    }),
    onSuccess() {
      qc.invalidateQueries(useUserInfoQueryOptions())
      qc.invalidateQueries(useSignRecordsQueryOptions())
    },
  })
}

export function useRepairSignMutation() {
  const qc = useQueryClient()
  return useMutation({
    mutationKey: ['daily-sign', 'repair'],
    mutationFn: (day: number) => client<ApiResult>('/api/daily/repair', {
      method: 'POST',
      body: {
        day,
      },
    }),
    onSuccess() {
      qc.invalidateQueries(useUserInfoQueryOptions())
      qc.invalidateQueries(useSignRecordsQueryOptions())
    },
  })
}

export function useStealMutation() {
  const qc = useQueryClient()
  return useMutation({
    mutationKey: ['steal'],
    // mutationFn: (cropCode: string, friendId: number) => client<ApiResult>('/api/user/steal'),
    mutationFn: ({ cropCode, friendId }: { cropCode: string, friendId: number }) => client<ApiResult<{ num: number }>>('/api/user/steal', {
      method: 'POST',
      body: {
        crop_code: cropCode,
        friend_id: friendId,
      },
    }),
    onSuccess() {
      qc.invalidateQueries(useUserInfoQueryOptions())
      qc.invalidateQueries(useFarmsQueryOptions())
      qc.invalidateQueries(useFriendsQueryOptions())
    },
  })
}

export function useCheckTaskMutation() {
  const qc = useQueryClient()
  return useMutation({
    mutationKey: ['check-task'],
    mutationFn: (code: string) => client<ApiResult>('/api/task/done', {
      method: 'POST',
      body: {
        code,
      },
    }),
    onSuccess() {
      qc.invalidateQueries(useTasksQueryOptions())
    },
  })
}

export function useClaimTaskRewardMutation() {
  const qc = useQueryClient()
  return useMutation({
    mutationKey: ['claim-task-reward'],
    mutationFn: (code: string) => client<ApiResult>('/api/task/claim', {
      method: 'POST',
      body: {
        code,
      },
    }),
    onSuccess() {
      qc.invalidateQueries(useUserInfoQueryOptions())
      qc.invalidateQueries(useTasksQueryOptions())
    },
  })
}

export function useSellCropMutation() {
  const qc = useQueryClient()
  return useMutation({
    mutationKey: ['sell', 'crop'],
    mutationFn: (body: { crop_code: string, num: number }) => client<ApiResult>('/api/user/sell', {
      method: 'POST',
      body,
    }),
    onSuccess() {
      qc.invalidateQueries(useUserInfoQueryOptions())
      qc.invalidateQueries(useWarehouseQueryOptions())
    },
  })
}

export function useBuyPropMutation() {
  const qc = useQueryClient()
  const { t } = useI18n()
  return useMutation({
    mutationKey: ['buy', 'prop'],
    // mutationFn: (body: {
    //   code: string
    //   type: 'tool' | 'jewel'
    // }) => client<ApiResult>('/api/package/buy', {
    //   method: 'POST',
    //   body,
    // }),
    mutationFn: async (body: { note: string, amount: string }) => {
      if (!window.deboxWallet) {
        throw new Error(t('warehouse.storeInDbox'))
      }
      await window.deboxWallet.request({
        method: 'wallet_requestPermissions',
        params: [{
          eth_accounts: {},
        }],
      })
      const result = await window.deboxWallet.request({
        method: 'debox_paymentVBox',
        params: [{
          receiver_address: '0x221a08592587b78eca54a7b65c9d456959fc007c',
          // amount: props.product.price, // 支付的 vBox 数量，最小值为 0.01
          amount: body.amount,
          nonce: new Date().valueOf(),
          note: body.note,
          // donation_amount: props.product.price * 0.05,
          donation_amount: body.amount,
        }],
      })

      if (result && typeof result === 'object' && 'order_id' in result) {
        const orderId = result.order_id

        const payInfoResult = await client<ApiResult>('/api/pay/info', { method: 'POST', body: { order_id: orderId } })
        if (payInfoResult.code === 1) {
          return orderId
        }
        else {
          throw new Error(payInfoResult.msg)
        }
      }
      else {
        console.error('payment result: ', result)
        throw new Error('Payment failed')
      }
    },
    onSuccess() {
      qc.invalidateQueries(useUserInfoQueryOptions())
      qc.invalidateQueries(useWarehouseQueryOptions())
      qc.invalidateQueries(usePurchaseRecordsQueryOptions())
    },
  })
}

export function useBuyDiamondMutation() {
  const qc = useQueryClient()
  const { t } = useI18n()
  return useMutation({
    mutationKey: ['buy', 'diamond'],
    mutationFn: async (body: { note: string, amount: string }) => {
      if (!window.deboxWallet) {
        throw new Error(t('warehouse.storeInDbox'))
      }
      await window.deboxWallet.request({
        method: 'wallet_requestPermissions',
        params: [{
          eth_accounts: {},
        }],
      })
      const result = await window.deboxWallet.request({
        method: 'debox_paymentVBox',
        params: [{
          receiver_address: '0x221a08592587b78eca54a7b65c9d456959fc007c',
          // amount: props.product.price, // 支付的 vBox 数量，最小值为 0.01
          amount: body.amount,
          nonce: new Date().valueOf(),
          note: body.note,
          // donation_amount: props.product.price * 0.05,
          donation_amount: body.amount,
        }],
      })
      if (result && typeof result === 'object' && 'order_id' in result) {
        const orderId = result.order_id

        const payInfoResult = await client<ApiResult>('/api/pay/info', { method: 'POST', body: { order_id: orderId } })
        if (payInfoResult.code === 1) {
          return orderId
        }
        else {
          throw new Error(payInfoResult.msg)
        }
      }
      else {
        console.error('payment result: ', result)
        throw new Error('Payment failed')
      }
    },
    onSuccess() {
      qc.invalidateQueries(useUserInfoQueryOptions())
      qc.invalidateQueries(useWarehouseQueryOptions())
      qc.invalidateQueries(usePurchaseRecordsQueryOptions())
    },
  })
}

export function useUnlockFarmMutation() {
  const qc = useQueryClient()
  return useMutation({
    mutationKey: ['unlock', 'farm'],
    mutationFn: (level: number) => client<ApiResult>('/api/user/unlock', {
      method: 'POST',
      body: {
        level,
      },
    }),
    onSuccess() {
      qc.invalidateQueries(useUserInfoQueryOptions())
      qc.invalidateQueries(useFarmsQueryOptions())
    },
  })
}

export function useUseMixturesMutation() {
  const qc = useQueryClient()
  return useMutation({
    mutationKey: ['use', 'mixtures'],
    mutationFn: (body: { code: string, num: number }) => client<ApiResult>('/api/user/useMixtures', {
      method: 'POST',
      body,
    }),
    onSuccess() {
      qc.invalidateQueries(useUserInfoQueryOptions())
      qc.invalidateQueries(useWarehouseQueryOptions())
    },
  })
}

export function useOpenBlindBoxMutation() {
  const qc = useQueryClient()
  return useMutation({
    mutationKey: ['use', 'blind-box'],
    mutationFn: (code: string) => client<ApiResult<BlindBoxResult>>('/api/user/openBox', {
      method: 'POST',
      body: {
        code,
      },
    }),
    onSuccess() {
      qc.invalidateQueries(useUserInfoQueryOptions())
      qc.invalidateQueries(useWarehouseQueryOptions())
    },
  })
}

export function usePrePaymentMutation() {
  const { t } = useI18n()
  return useMutation({
    mutationKey: ['pre-payment'],
    mutationFn: ({ type }: { type: string }) => {
      if (!isDeBoxApp())
        throw new Error(t('warehouse.storeInDbox'))
      return client<ApiResult<{ url: string }>>('/api/pay/auth', {
        method: 'POST',
        body: { type },
      })
    },
  })
}

export function usePaymentMutation() {
  const qc = useQueryClient()
  return useMutation({
    mutationFn: ({ code, serial_id }: { code: string, serial_id: string }) => client<ApiResult>('/api/pay/pay', {
      method: 'POST',
      body: { code, serial_id },
    }),
    mutationKey: ['payment'],
    onSuccess() {
      qc.invalidateQueries(useUserInfoQueryOptions())
    },
  })
}
