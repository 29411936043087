import type { ApiListResult, ApiListResultV2, ApiResult, Farm, Friend, InviteRecord, LevelsResult, Pagination, Prop, PurchaseRecord, RankingItem, SignInRecord as SignRecord, StealRecordsResult, StoreJewelsItem, StorePropItem, Task, UserInfo, WarehouseCrop } from '@/lib/api/models'
import type { MaybeRefOrGetter } from 'vue'
import { client, kDefaultPagination } from '@/lib/api'
import { dayjs } from '@/lib/utils/day'
import * as Sentry from '@sentry/vue'
import { infiniteQueryOptions, queryOptions } from '@tanstack/vue-query'

export * from './mutations'

export function usePagination(initital: Pagination = kDefaultPagination) {
  return ref(structuredClone(initital))
}

export function useGoolgeAuthUrlQueryOptions() {
  return queryOptions({
    queryKey: ['auth-url', 'google'],
    queryFn: () => client<ApiResult<{ url: string }>>('/api/user/googleAuthUrl'),
    select: ({ data: { url } }) => url,
  })
}

export function useFarmsQueryOptions() {
  return queryOptions({
    queryKey: ['user', 'farms'],
    queryFn: () => client<ApiResult<Farm[]>>('/api/user/level'),
    select: ({ data }) => data.map((farm, index) => ({
      ...farm,
      isPrimary: index === 0,
      endAt: dayjs().add(farm.collect.countdown, 'seconds').valueOf(),
    })),
  })
}

export function useUserInfoQueryOptions() {
  return queryOptions({
    queryKey: ['user', 'info'],
    queryFn: () => client<ApiResult<UserInfo>>('/api/user/info'),
    select: ({ data }) => {
      // data.mixtures_countdown = 3
      Sentry.setUser({
        ...data,
        id: data.id.toString(),
        username: data.nickname,
        email: data.email,
        ip_address: '{{auto}}',
        user_agent: '{{auto}}',
      })
      return {
        ...data,
        mixtures_end_at: data.mixtures_countdown > 0 ? dayjs().add(data.mixtures_countdown, 'seconds').valueOf() : undefined,
      }
    },
  })
}

export function useSignRecordsQueryOptions() {
  return queryOptions({
    queryKey: ['user', 'sign-records'],
    queryFn: () => client<ApiResult<SignRecord[]>>('/api/daily/index'),
    select: ({ data }) => data,
  })
}

export function useFriendsQueryOptions() {
  return queryOptions({
    queryKey: ['user', 'friends'],
    queryFn: () => client<ApiListResult<Friend>>('/api/friend/index', {
      query: { limit: Number.MAX_SAFE_INTEGER },
    }),
    select: ({ data }) => data,
  })
}

export function useFriendFarmQueryOptions(id: MaybeRefOrGetter<number>) {
  return queryOptions({
    queryKey: ['user', 'friend-farms', id],
    queryFn: () => client<ApiResult<Farm[]>>('/api/friend/farm', {
      query: { user_id: toValue(id) },
    }),
    select: ({ data }) => data.map((farm, index) => ({
      ...farm,
      isPrimary: index === 0,
      endAt: dayjs().add(farm.collect.countdown, 'seconds').valueOf(),
    })),
  })
}

export function useFriendInvitedsQueryOptions() {
  return queryOptions({
    queryKey: ['user', 'friend-inviteds'],
    queryFn: () => client<ApiListResult<InviteRecord>>('/api/friend/invited', {
      query: { limit: Number.MAX_SAFE_INTEGER },
    }),
    select: ({ data }) => data,
  })
}

export function useTasksQueryOptions() {
  return queryOptions({
    queryKey: ['user', 'tasks'],
    queryFn: () => client<ApiResult<Task[]>>('/api/task/index'),
    select: ({ data }) => data,
  })
}

export function useRankingTodayQueryOptions() {
  return queryOptions({
    queryKey: ['ranking', 'today'],
    queryFn: () => client<ApiListResult<RankingItem>>('/api/ranking/today', {
      query: { limit: 50 },
    }),
    select: ({ data }) => data,
  })
}

export function useRankingQueryOptions() {
  return queryOptions({
    queryKey: ['ranking'],
    queryFn: () => client<ApiListResult<RankingItem>>('/api/ranking/index', {
      query: { limit: 50 },
    }),
    select: ({ data }) => data,
  })
}

export function useWarehouseQueryOptions() {
  return queryOptions({
    queryKey: ['user', 'warehouse', 'items'],
    queryFn: () => client<ApiResult<{ tools: Prop[], crops: WarehouseCrop[] }>>('/api/package/index', {
      query: { limit: Number.MAX_SAFE_INTEGER },
    }),
    select: ({ data }) => data,
  })
}

export function useWarehouseStoreQueryOptions() {
  return queryOptions({
    queryKey: ['store', 'warehouse', 'store'],
    queryFn: () => client<ApiResult<{ tools: StorePropItem[], jewels: StoreJewelsItem[] }>>('/api/package/store'),
    select: ({ data }) => data,
  })
}

/**
 * @inititalPagination pagination limit not work on this api
 */
export function usePurchaseRecordsQueryOptions(inititalPagination: MaybeRefOrGetter<Omit<Pagination, 'limit'>> = kDefaultPagination) {
  return infiniteQueryOptions({
    queryKey: ['user', 'purchase-history'],
    queryFn: ({ pageParam }) => client<ApiListResultV2<PurchaseRecord>>('/api/package/record', {
      query: { page: pageParam.page },
    }),
    initialPageParam: toValue(inititalPagination),
    select: ({ pages }) => pages.map(({ data }) => data.data).flat(),
    getNextPageParam(lastPage, pages, lastPageParam) {
      if (!lastPage.data || lastPage.data.data.length < lastPage.data.per_page)
        return undefined
      return {
        page: lastPageParam.page + 1,
      }
    },
  })
}

export function useFriendInfo(userId: MaybeRefOrGetter<number>) {
  return queryOptions({
    queryKey: ['user', 'friend-info', userId],
    queryFn: () => client<ApiResult<{ avatar: string, vip: number }>>('/api/friend/info', {
      query: { user_id: toValue(userId) },
    }),
    select: ({ data }) => data,
  })
}
export function useStealRecordsQueryOptions() {
  return queryOptions({
    queryKey: ['steal', 'records'],
    queryFn: () => client<ApiResult<StealRecordsResult[]>>('/api/user/stealRecord'),
    select: ({ data }) => data,
  })
}

export function useLevelsQueryOptions() {
  return queryOptions({
    queryKey: ['user', 'levels'],
    queryFn: () => client<ApiResult<LevelsResult[]>>('/api/index/levels'),
    select: ({ data }) => data,
  })
}
